<template>
  <div class="inner-pages hd-white">
    <div id="wrapper">
      <img :src="require('../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />
      <div id="login">
        <div class="login container">
          <h2>Contact Us</h2>
          <p class="mt-3">
            Here at vencasa we love hearing from our customers.
          </p>
          <p class="mt-3">
            Please feel free to contact us about anything on your mind, whether
            it be needing help with something; improvements or suggestions you
            would like to see, or even just to say hi.
          </p>
          <p class="mt-3">
            We will aim to get back to you as soon as possible.
          </p>
          <p class="mt-3 mb-3">
            We look forward to hearing what you have to say.
          </p>
          <form @submit.prevent="" autocomplete="off">
            <div class="form-group">
              <input
                style="height: 50px"
                class="form-control"
                v-model="name"
                placeholder="Name"
                type="text"
              />
              <p class="error" v-if="$v.name.$dirty && !$v.name.required">
                Name is required!
              </p>
              <i class="ti-user"></i>
            </div>

            <div class="form-group">
              <input
                style="height: 50px"
                class="form-control"
                v-model="email"
                placeholder="Email"
                type="email"
              />
              <p class="error" v-if="$v.email.$dirty && !$v.email.required">
                Email is required!
              </p>
              <i class="icon_mail_alt"></i>
            </div>
            <div class="form-group">
              <textarea
                style="height: 180px"
                class="form-control"
                placeholder="Message"
                v-model="message"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
              <p class="error" v-if="$v.message.$dirty && !$v.message.required">
                Message is required!
              </p>
            </div>
            <div id="pass-info" class="clearfix"></div>
            <button
              @click="submit"
              style="outline: none; color: white; width: 200px; margin: auto; border-radious: 0px !important;"
              class="
                btn_1
                rounded
                full-width
                add_top_30
                resbutton-contact
                res-button-how
              "
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div class="login-and-register-form modal">
        <div class="main-overlay"></div>
        <div class="main-register-holder">
          <div class="main-register fl-wrap">
            <div class="close-reg"><i class="fa fa-times"></i></div>
            <h3>
              Welcome to <span>Find<strong>Houses</strong></span>
            </h3>
            <div class="soc-log fl-wrap">
              <p>Login</p>
              <a href="#" class="facebook-log"
                ><i class="fa fa-facebook-official"></i>Log in with Facebook</a
              >
              <a href="#" class="twitter-log"
                ><i class="fa fa-twitter"></i> Log in with Twitter</a
              >
            </div>
            <div class="log-separator fl-wrap"><span>Or</span></div>
            <div id="tabs-container">
              <ul class="tabs-menu">
                <li class="current"><a href="#tab-1">Login</a></li>
                <li><a href="#tab-2">Register</a></li>
              </ul>
              <div class="tab">
                <div id="tab-1" class="tab-contents">
                  <div class="custom-form">
                    <form method="post" name="registerform">
                      <label>Username or Email Address * </label>
                      <input name="email" type="text" value="" />
                      <label>Password * </label>
                      <input name="password" type="password" value="" />
                      <button type="submit" class="log-submit-btn">
                        <span>Log In</span>
                      </button>
                      <div class="clearfix"></div>
                      <div class="filter-tags">
                        <input id="check-a" type="checkbox" name="check" />
                        <label for="check-a">Remember me</label>
                      </div>
                    </form>
                    <div class="lost_password">
                      <a href="#">Lost Your Password?</a>
                    </div>
                  </div>
                </div>
                <div class="tab">
                  <div id="tab-2" class="tab-contents">
                    <div class="custom-form">
                      <form
                        @submit.prevent=""
                        name="registerform"
                        class="main-register-form"
                        id="main-register-form2"
                      >
                        <label>First Name * </label>
                        <input name="name" type="text" value="" />
                        <label>Second Name *</label>
                        <input name="name2" type="text" value="" />
                        <label>Email Address *</label>
                        <input name="email" type="text" value="" />
                        <label>Password *</label>
                        <input name="password" type="password" value="" />
                        <span class="field"></span>
                        <label>Confirm Password *</label>
                        <input name="password" type="password" value="" />
                        <button type="submit" class="log-submit-btn">
                          <span>Register</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
    },
    message: {
      required,
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          name: this.name,
          email: this.email,
          message: this.message,
        };
        this.$store.dispatch("contactUs", data).then((res) => {
          if (res == true) {
            this.$v.$reset();
            this.name = "";
            this.email = "";
            this.message = "";
            this.$store.commit("setMessage", "Email sent");
          } else
            this.$store.commit(
              "setMessage",
              "Email was not sent"
            );
        });
      }
    },
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>

#login .login,
#register .login {
  width: 500px;
  padding-top: 0px;
}
.form-control {
  border-radius: 8px;
}
h2 {
  text-transform: inherit;
  font-size: 1.3em;
}

p {
  font-weight: 500;
}

@media (min-width: 350px) and (max-width: 430px) {
  .login {
    width: 100% !important;
  }

  .resbutton-contact {
    margin: auto !important;
    float: none !important;
  }
}
</style>
